import React, { useCallback, useEffect, useState } from "react";
import { Button, Space, Typography, Divider, Input, message } from "antd";
import "./TaskManager.css";
import { If } from "../components";

enum TaskStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

interface Task {
  taskName: string;
  taskStatus: TaskStatus;
  editing: boolean;
}

export function TaskManager() {
  const [taskName, setTaskName] = useState("");
  const [allTasks, setAllTasks] = useState<Task[]>(() => {
    const data = localStorage.getItem("taskManagerTasks");
    if (data) return JSON.parse(data);
    return [];
  });
  const [editingTask, setEditingTask] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const syncWithLocalStorage = useCallback(() => {
    localStorage.removeItem("taskManagerTasks");
    localStorage.setItem("taskManagerTasks", JSON.stringify(allTasks));
  }, [allTasks]);

  const onAddTask = useCallback(() => {
    if (!taskName) return;
    setAllTasks((oldTasks) => [
      ...oldTasks,
      { taskName, taskStatus: TaskStatus.PENDING, editing: false },
    ]);
    setTaskName("");
  }, [taskName]);

  const onDeleteTask = useCallback(
    (deletingIndex: number) => {
      setAllTasks((oldTasks) =>
        oldTasks.filter((_, index) => index !== deletingIndex)
      );
      messageApi.open({
        type: "success",
        content: "Task deleted successfully",
        duration: 10,
      });
    },
    [messageApi]
  );

  const onMarkAsCompleted = useCallback((completingIndex: number) => {
    setAllTasks((oldTasks) =>
      oldTasks.map((item, index) => {
        if (index === completingIndex)
          return {
            ...item,
            taskStatus:
              item.taskStatus === TaskStatus.COMPLETED
                ? TaskStatus.PENDING
                : TaskStatus.COMPLETED,
          };
        else return item;
      })
    );
  }, []);

  const onMarkInProgress = useCallback((inProgressIndex: number) => {
    setAllTasks((oldTasks) =>
      oldTasks.map((item, index) => {
        if (index === inProgressIndex)
          return {
            ...item,
            taskStatus:
              item.taskStatus === TaskStatus.IN_PROGRESS
                ? TaskStatus.PENDING
                : TaskStatus.IN_PROGRESS,
          };
        else return item;
      })
    );
  }, []);

  const onEdit = useCallback((editingIndex: number) => {
    setAllTasks((oldTasks) =>
      oldTasks.map((item, index) => {
        if (index === editingIndex) {
          if (!item.editing) setEditingTask(item.taskName);
          return {
            ...item,
            editing: item.editing ? false : true,
          };
        } else return item;
      })
    );
  }, []);

  const saveTaskName = useCallback((taskIndex: number, taskText: string) => {
    setAllTasks((oldTasks) =>
      oldTasks.map((item, index) => {
        if (index === taskIndex) {
          return {
            ...item,
            taskName: taskText,
            editing: false,
          };
        } else return item;
      })
    );
  }, []);

  useEffect(() => {
    syncWithLocalStorage();
  }, [syncWithLocalStorage]);

  return (
    <div className="task-manager">
      {contextHolder}
      <Typography.Title>Task Manager</Typography.Title>
      <Divider />
      <Space direction="vertical" style={{ width: "30%" }}>
        <Input
          placeholder="Enter Task Name"
          onChange={(e) => setTaskName(e.target.value)}
          value={taskName}
        />
        <Button onClick={onAddTask}>Add Task</Button>
      </Space>
      <Divider />
      <Space direction="vertical">
        {allTasks.map((item, index) => (
          <Space size="small" direction="vertical">
            <Typography.Title level={5}>
              <If condition={item.editing}>
                <Space direction="vertical">
                  <label>Edit Task</label>
                  <Space>
                    <Input
                      value={editingTask}
                      onChange={(e) => setEditingTask(e.target.value)}
                    />
                    <Button onClick={() => saveTaskName(index, editingTask)}>
                      Save
                    </Button>
                    <Button onClick={() => onEdit(index)} danger>
                      Cancel
                    </Button>
                  </Space>
                </Space>
              </If>
              <If
                condition={
                  item.taskStatus === TaskStatus.COMPLETED && !item.editing
                }
              >
                <s>{item.taskName}</s>
              </If>
              <If
                condition={
                  item.taskStatus === TaskStatus.IN_PROGRESS && !item.editing
                }
              >
                <span style={{ color: "orange" }}>{item.taskName}</span>
              </If>
              <If
                condition={
                  item.taskStatus === TaskStatus.PENDING && !item.editing
                }
              >
                {item.taskName}
              </If>
            </Typography.Title>
            <Space>
              <Button onClick={() => onMarkAsCompleted(index)}>
                Mark as completed
              </Button>
              <Button onClick={() => onMarkInProgress(index)}>
                Mark In Progress
              </Button>
              <If condition={!item.editing}>
                <Button onClick={() => onEdit(index)}>Edit</Button>
              </If>
              <Button onClick={() => onDeleteTask(index)} danger>
                Delete
              </Button>
            </Space>
          </Space>
        ))}
      </Space>
    </div>
  );
}
