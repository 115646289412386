import React from "react";

interface IfProps {
  condition: boolean;
  children: any;
}

export function If({ condition, children }: IfProps) {
  if (condition) return children;
  return null;
}
